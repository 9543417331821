<template>
  <div class="login-container">
    <!-- 背景视频 -->
    <video
      :style="fixStyle"
      class="video-box"
      autoplay
      loop
      muted
    >
      <source
        src="../assets/video/inti.mp4"
        type="video/mp4"
      />
    </video>

    <!-- 语言切换 -->
    <div class="language-box">
      <el-select
        v-model="lang.value"
        popper-class="public-select-dropdown"
        @change="langChange"
      >
        <el-option
          v-for="item in lang.options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>

    <!-- 内容区域 -->
    <div class="content-box">
      <!-- logo、商会名称 -->
      <div class="chamber-name-box">
        <div class="chamber-left">
          <img src="@/assets/imgs/Logo_INTI.jpg" />
        </div>
        <div class="chamber-right">
          <p>{{ $t('Title1') }}</p>
          <p>{{ $t('Title2') }}</p>
        </div>
      </div>

      <!-- 表单区域 -->
      <div class="form-box">
        <el-form
          :model="form"
          :rules="formRules"
          ref="formRef"
        >
          <!-- 账号 -->
          <el-form-item
            prop="account"
            class="account-item"
          >
            <el-input
              v-model.trim="form.account"
              prefix-icon="iconfont icon-zhanghao left-icon"
              :placeholder="$t('word1002')"
            >
            </el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item
            prop="password"
            class="password-item"
          >
            <el-input
              v-model.trim="form.password"
              prefix-icon="iconfont icon-mima left-icon"
              :placeholder="$t('word1003')"
              show-password
            >
            </el-input>
          </el-form-item>
          <!-- 忘记密码 -->
          <el-form-item class="forget-password-item">
            <span
              class="forget-password"
              @click="$router.push({ name: 'forgetPassword' })"
            >{{ $t('word1004') }}</span>
          </el-form-item>
          <!-- 登录/注册 -->
          <el-form-item class="btn-item">
            <el-button
              class="btn-box"
              @click="loginIn"
            >{{ $t('word1005') }}</el-button>
            <el-button
              class="btn-box"
              @click="$router.push({ name: 'register' })"
            >{{ $t('word1006') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/mixin.js'
import {
  loginIn,
  getChamberById,
  getChamberLanguage,
  getMembershipFeeDetail,
  addList,
  getUserIdAndChamberId
} from '@/api/api.js'
import { setCookie } from '@/js/tool.js'

export default {
  name: 'Login',
  mixins: [mixin],
  data() {
    return {
      fixStyle: '',
      // 语言数据
      lang: {
        options: [
          {
            value: 'zh-CN',
            label: '简体中文'
          },
          {
            value: 'en-US',
            label: 'English'
          },
          {
            value: 'bahasa-Indonesia',
            label: 'Bahasa Indonesia'
          },
          /* {
            value: 'Malay',
            label: 'Bahasa Melayu'
          },
          {
            value: 'Thai',
            label: 'ภาษาไทย'
          },

          {
            value: 'Vietnamese',
            label: 'Tiếng Việt'
          },
          {
            value: 'Burmese',
            label: 'ဗမာစာ'
          },
          {
            value: 'Lao',
            label: 'ພາສາລາວ'
          },
          {
            value: 'Khmer',
            label: 'ភាសាខ្មែរ'
          },
          {
            value: 'Filipino',
            label: 'Wikang Filipino'
          },
          {
            value: 'Portuguese',
            label: 'Português'
          }, */
        ],
        value: sessionStorage.getItem('locale') ? sessionStorage.getItem('locale') : 'zh-CN'
      },
      // 表单对象
      form: {
        account: '',
        password: ''
      },
      // 表单校验对象
      formRules: {
        account: [{ required: true, message: this.$t('word1010'), trigger: 'blur' }],
        password: [{ required: true, message: this.$t('word1011'), trigger: 'blur' }]
      }
    }
  },
  beforeCreate() {
    if (sessionStorage.getItem('changeChange') != 'yes') {
      sessionStorage.setItem('locale', 'en-US')
    }
    sessionStorage.setItem('changeChange', 'no')
  },
  created() {
    setCookie('tenantCode', '1000004')
  },
  mounted() {
    this.synchronizeWindowSize()
  },
  methods: {
    // 主要是检测窗口缩放视频也同步大小
    synchronizeWindowSize() {
      window.onresize = () => {
        const windowWidth = document.body.clientWidth
        const windowHeight = document.body.clientHeight
        const windowAspectRatio = windowHeight / windowWidth
        let videoWidth
        let videoHeight
        if (windowAspectRatio < 0.5625) {
          videoWidth = windowWidth
          videoHeight = videoWidth * 0.5625
          this.fixStyle = {
            height: windowWidth * 0.5625 + 'px',
            width: windowWidth + 'px',
            'margin-bottom': (windowHeight - videoHeight) / 2 + 'px',
            'margin-left': 'initial'
          }
        } else {
          videoHeight = windowHeight
          videoWidth = videoHeight / 0.5625
          this.fixStyle = {
            height: windowHeight + 'px',
            width: windowHeight / 0.5625 + 'px',
            'margin-left': (windowWidth - videoWidth) / 2 + 'px',
            'margin-bottom': 'initial'
          }
        }
      }
      window.onresize()
    },

    // 语言切换
    langChange(value) {
      const lang = value === 'zh-CN' ? 'zh_cn' : value === 'en-US' ? 'en' : value
      this.setCookie('org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE', lang)
      sessionStorage.setItem('locale', value)
      sessionStorage.setItem('changeChange', 'yes')
      window.location.reload() // 刷新页面
    },

    // 登录
    loginIn() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const { account, password } = this.form
          const params = {
            mobile: account,
            password,
            osType: '2',
            clientTags: this.getBrowser('v'),
            mac: this.getBrowser(),
            auroraId: ''
          }
          loginIn(params).then(res => {
            if (res.code === 503 && res.msg === 'TheAccountHasBeenFrozen') {
              // 该账号已被冻结
              this.$message.error({ message: this.$t('word1012'), duration: 2000 })
            } else if (res.code === 900119) {
              // 该账号已被删除
              this.$alert(this.$t('DeleteTip'), this.$t('word1058'), {
                confirmButtonText: this.$t('word1030'),
                callback: action => {}
              })
            } else if (res.code === 504 && res.msg === 'accountDisabled') {
              // 该账号已被禁止
              this.$message.error({ message: this.$t('word1035'), duration: 2000 })
            } else if (res.code === 900126) {
              // 后台管理员设置了会费，在缴费有效期内，但未支付会费
              const paymentEndTime = res.data.paymentEndTime.substring(0, 10)
              const msgText = `${this.$t('QuickPaymentTip')} 【${paymentEndTime}】 ${this.$t('UnableLoginTip')}`
              this.$confirm(msgText, this.$t('Prompt'), {
                confirmButtonText: this.$t('MembershipDues'),
                cancelButtonText: this.$t('Determine'),
                type: 'warning',
                closeOnClickModal: false, // 是否可以点击空白处关闭弹窗
                closeOnPressEscape: false, // 是否可以通过按ESC键关闭弹窗
                showClose: false // 隐藏右上角x号
              })
                .then(() => {
                  getUserIdAndChamberId({
                    email: this.form.account,
                    tenantCode: '1000004'
                  }).then(uRes => {
                    setCookie('id', uRes.data.id)
                    setCookie('chamberId', uRes.data.chamberId)
                    getMembershipFeeDetail({
                      userId: uRes.data.id,
                      chamberId: uRes.data.chamberId,
                      language: this.languageParams
                    }).then(mRes => {
                      //生产环境相对地址https://hninvestgo.commerce-client.com
                      window.location.href = `/weba/#/pay?eventPrice=${mRes.data.membershipFee}&isFreeze=1`
                    })
                  })
                })
                .catch(() => {
                  this.goLoginIn(res)
                })
            } else if (res.code === 504 && res.msg === 'User time has expired') {
              // 超过缴费结束时间，未支付会费
              this.$confirm(this.$t('MembershipExpirationTip'), this.$t('Prompt'), {
                confirmButtonText: this.$t('MembershipDues'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
              })
                .then(() => {
                  getUserIdAndChamberId({
                    email: this.form.account,
                    tenantCode: '1000004'
                  }).then(uRes => {
                    setCookie('id', uRes.data.id)
                    setCookie('chamberId', uRes.data.chamberId)
                    getMembershipFeeDetail({
                      userId: uRes.data.id,
                      chamberId: uRes.data.chamberId,
                      language: this.languageParams
                    }).then(mRes => {
                      //生产环境相对地址https://hninvestgo.commerce-client.com
                      window.location.href = `/weba/#/pay?eventPrice=${mRes.data.membershipFee}&isFreeze=1`
                    })
                  })
                })
                .catch(() => {})
            } else {
              this.goLoginIn(res)
            }
          })
        } else {
          return false
        }
      })
    },

    // 去登录
    goLoginIn(res) {
      if (res.data.id === '0') return this.$message.error({ message: this.$t('word1013'), duration: 2000 })
      if (res.data.enable == 1) return this.$message.error({ message: this.$t('word1013'), duration: 2000 })
      if (!res.data.chamberId) return this.$message.error({ message: this.$t('word1013'), duration: 2000 })
      // inti tenantCode 为 1000004
      if (res.data.tenantCode !== '1000004')
        return this.$message.error({ message: this.$t('word1013'), duration: 2000 })

      setCookie('chamberId', res.data.chamberId)
      var chamberId = res.data.chamberId
      getChamberById({
        chamberId: chamberId
      }).then(res2 => {
        var tenantAthenaChamberDTO = res2.data
        if (tenantAthenaChamberDTO) {
          sessionStorage.chamberInfo = JSON.stringify(tenantAthenaChamberDTO)
          setCookie('chamberName', tenantAthenaChamberDTO.name)
          setCookie('tenantCode', res.data.tenantCode)
          getChamberLanguage({
            tenantCode: res.data.tenantCode
          }).then(res3 => {
            var tenantAthenaChamberDTO3 = res3.data
            if (tenantAthenaChamberDTO3) {
              sessionStorage.originalLanguage = tenantAthenaChamberDTO3.language
              // sessionStorage.locale = sessionStorage.originalLanguage
              this.saveUserInfo(res.data)

              // 用户登录、用户注册都需要请求该接口
              const params = {
                userType: 1, // 1:商会用户、2:律所用户
                systemId: res.data.chamberId, // 商会id
                userId: res.data.id // 用户id
              }
              addList([params]).then(() => {
                this.$message.success({ message: this.$t('word1014'), duration: 1500 })
                //相对地址https://inti.commerce-client.com
                window.location.href = '/weba/#/defaultPage'
              })
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  position: relative;
  height: 100%;
  overflow: hidden;

  // 背景视频
  .video-box {
    position: absolute;
    z-index: 0;
  }

  // 语言切换
  .language-box {
    position: absolute;
    top: 40px;
    left: 80px;
    z-index: 2;
    width: 160px;
    ::v-deep .el-select {
      .el-input__inner {
        background-color: rgba(255, 255, 255, 0.8);
        color: #43403d;
      }
      .el-select__caret {
        color: #43403d;
      }
    }
  }

  // 内容区域
  .content-box {
    position: absolute;
    left: 80px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    // logo、商会名称
    .chamber-name-box {
      display: flex;
      align-items: center;
      width: 700px;
      margin-bottom: 50px;
      & > .chamber-left {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        & > img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      & > .chamber-right {
        flex: 1;
        width: 0;
        flex-wrap: wrap;
        color: #ffffff;
        font-size: 20px;
        & > p:first-child {
          margin-bottom: 10px;
        }
      }
    }

    // 表单区域
    .form-box {
      width: 400px;
      ::v-deep .el-form {
        .el-input__inner {
          background-color: transparent;
          color: #ffffff;
          border: none;
          border-bottom: 1px solid #ffffff;
          border-radius: 0;
        }
        .el-input--prefix .el-input__inner {
          padding-left: 40px;
        }
        .left-icon {
          color: #ffffff;
          font-size: 24px;
        }
        // 账号
        .account-item {
          margin-bottom: 40px;
        }
        // 忘记密码
        .forget-password-item {
          margin-bottom: 40px;
          .el-form-item__content {
            line-height: 16px;
          }
          .forget-password {
            color: #ffffff;
            cursor: pointer;
          }
        }
        // 按钮
        .btn-item {
          margin-bottom: 0;
          .el-form-item__content {
            text-align: center;
            .el-button + .el-button {
              margin-left: 40px;
            }
            .el-button {
              background: rgba(255, 255, 255, 0.8);
              padding: 12px 40px;
              color: #43403d;
            }
          }
        }
      }
    }
  }
}
</style>